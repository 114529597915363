define("discourse/plugins/brec/discourse/brec-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
   * verlinkt Pfad `/notebook` zur Route `notebook`. Eine Route mit dem
   * gleichen Namen muss im `routes` Ordner angelegt sein.
   */
  function _default() {
    this.route('brec', {
      path: '/brec'
    });
  }
});