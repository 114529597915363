define("discourse/plugins/brec/initializers/brec", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeBrec(api) {
    const user = api.getCurrentUser();
    const settings = api.container.lookup("site-settings:main");
    const visibleforgroupnums = settings.brec_groups.split("|").filter(x => x.length).map(Number);
    const amInGroupNums = a => user && user.groups.filter(x => a.indexOf(x.id) > -1).length > 0;
    if (amInGroupNums(visibleforgroupnums)) {
      api.decorateWidget(`hamburger-menu:generalLinks`, () => {
        return {
          href: "/brec",
          rawLabel: "BBB Aufnahmen",
          className: "brec-button",
          attributes: {}
        };
      });
    }
  }
  var _default = _exports.default = {
    name: "brec",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initializeBrec);
    }
  };
});