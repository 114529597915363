define("discourse/plugins/brec/discourse/routes/brec", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Route für den Pfad `/brec` wie in `../brec-route-map.js.es6` definiert.
   */
  var _default = _exports.default = _discourse.default.extend({
    renderTemplate() {
      // Renders the template `../templates/brec.hbs`
      this.render('brec');
    }
  });
});