define("discourse/plugins/brec/discourse/controllers/brec", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    init() {
      this._super();
      this.set('breclist', []);
      // this.fetchBrecs();
      this.getbrecs();
    },
    getbrecs() {
      // $("div#breclist").text("ey jo");
      const re_iddate = /-([0-9]{13})$/;
      const d2 = n => n <= 9 ? `0${n}` : n;
      const ms2hm = t => t >= 60000 ? `${Math.floor(t / 60000)}:${d2(Math.floor(t / 1000 % 60))}m` : `${Math.floor(t / 1000)}s`;
      const ib = s => {
        let n = Math.floor(Math.log2(s) / 10);
        return Math.round(s / Math.pow(1024, n) * 100) / 100 + " " + ",k,M,G,T,P,E,Z,Y".split(",")[Math.floor(Number(n))] + "B";
      };
      $.ajax("/kista-bbb/records.json", {
        "method": "POST",
        "success": e => {
          window.breclist = e.kista_bbb;
          for (const folder of e.kista_bbb) {
            for (const r of folder.recordings) {
              r.dur = r.stop - r.start;
              r.hdur = ms2hm(r.dur);
              r.datestr = new Date(r.start).toLocaleString("de-DE");
              r.session = folder.session;
              for (const a of r.audios) {
                for (let x of [a["mp4"], a["webm"]]) {
                  x["hsize"] = ib(x["size"]);
                }
                a.dur = a.stop - a.start;
                a.hdur = ms2hm(a.dur);
                for (const v of a.videos) {
                  for (let x of [v["mp4"], v["webm"]]) {
                    x["hsize"] = ib(x["size"]);
                  }
                  v.dur = v.stop - v.start;
                  v.hdur = ms2hm(v.dur);
                }
              }
              this.breclist.pushObject(r);
            }
          }
        }
      });
    },
    fetchBrecs() {
      this.store.findAll('brec').then(result => {
        for (const item of result.content) {
          this.breclist.pushObject(item);
        }
      }).catch(console.error);
    },
    actions: {
      createBrec(content) {
        if (!content) {
          return;
        }
        const noteRecord = this.store.createRecord('brec', {
          id: Date.now(),
          content: content
        });
        noteRecord.save().then(result => {
          this.breclist.pushObject(result.target);
        }).catch(console.error);
      },
      deleteBrec(item) {
        this.store.destroyRecord('brec', item).then(() => {
          this.breclist.removeObject(item);
        }).catch(console.error);
      }
    }
  });
});